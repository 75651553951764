import React, { ComponentPropsWithoutRef } from "react"
import { mergeClasses } from "utils"

type P = ComponentPropsWithoutRef<"a">

export const SocialBtn = ({ className = "", children, ...props }: P) => {
	return (
		<a
			className={mergeClasses({
				classes: [
					"bg-theme-blue text-sm sm:text-base py-2 sm:py-4 px-4 sm:px-8 rounded-md flex items-center gap-2 sm:gap-3 justify-center cursor-pointer",
					className,
				],
			})}
			{...props}
		>
			{children}
		</a>
	)
}
