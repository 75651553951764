import React, { ReactNode } from "react"
import HeroImage from "images/qrcode/hero.svg"
import FooterImage from "images/qrcode/footer.svg"
import { Mail, Phone, Linkedin, Globe, File, Video } from "react-feather"
import { useI18next, useTranslation } from "gatsby-plugin-react-i18next"
import { PrimaryBtn } from "components/library"
import { TranslationKey } from "types/translation"
import { SocialBtn } from "./social-btn"
import { getBaseUrlByLanguage } from "utils/general/language"

export type PersonDetails = {
	name: TranslationKey
	designation: TranslationKey
	image: string
	contact: {
		email: string
		phone: string
		website: string
		linkedin: string
	}
	address: string[]
}

type P = {
	name: string
	designation: string
	image: string
	personal_note?: ReactNode
	contact: {
		email: string
		phone: string
		website: string
		linkedin: string
	}
}

export const ProfileCard = ({
	contact,
	designation,
	image,
	name,
	personal_note,
}: P) => {
	const { language } = useI18next()
	const { t } = useTranslation()

	return (
		<section className="bg-white">
			<div className="relative">
				<img
					className="w-full"
					src={HeroImage}
					alt="polymerize"
					loading="eager"
				/>

				{image && <div className="h-16 w-16 sm:w-40 sm:h-40 rounded-full bg-white absolute -bottom-8 sm:-bottom-20 left-1/2 transform -translate-x-1/2 overflow-hidden p-1 sm:p-[6px]">
					<img
						src={image}
						alt={name}
						className="w-full h-full object-cover rounded-full"
					/>
				</div>}
			</div>

			<div className="flex flex-col pt-12 sm:pt-28 pb-5 sm:pb-8 px-4">
				<h1 className="text-xl sm:text-5xl font-medium text-center">{name}</h1>
				<p className="text-sm sm:text-2xl text-center">{designation}</p>
			</div>

			<div className="border-y-[.5px] border-[#305CBD]/40 py-2 sm:py-8 flex flex-col items-center justify-center gap-4">
				<div className="grid grid-cols-1 w-full sm:w-auto sm:grid-cols-3 text-white gap-x-6 sm:gap-x-12 gap-y-6 p-4 text-lg select-none font-semibold">
					<SocialBtn href={`mailto:${contact.email}`}>
						<Mail
							className="shrink-0"
							size={window.innerWidth < 640 ? 16 : 24}
						/>
						<span>{t("common.words.email")}</span>
					</SocialBtn>
					{contact.phone !== '' &&<SocialBtn href={`tel:${contact.phone}`}>
						<Phone
							className="shrink-0"
							size={window.innerWidth < 640 ? 16 : 24}
						/>
						<span>{t("common.words.call")}</span>
					</SocialBtn>}
					<SocialBtn target="_blank" href={contact.linkedin}>
						<Linkedin
							className="shrink-0"
							size={window.innerWidth < 640 ? 16 : 24}
						/>
						<span>{t("common.words.linkedin")}</span>
					</SocialBtn>
					<SocialBtn
						target="_blank"
						href={getBaseUrlByLanguage(language)}
					>
						<Globe
							className="shrink-0"
							size={window.innerWidth < 640 ? 16 : 24}
						/>
						<span>{t("common.words.website")}</span>
					</SocialBtn>
					<SocialBtn target="_blank" href="/case-study/">
						<File
							className="shrink-0"
							size={window.innerWidth < 640 ? 16 : 24}
						/>
						<span>{t("common.words.case-study")}</span>
					</SocialBtn>
					<SocialBtn
						target="_blank"
						href="https://drive.google.com/file/d/1Rqsdsy5EGg-raEcPPwV4oidg5_4QEbK1/view"
					>
						<Video
							className="shrink-0"
							size={window.innerWidth < 640 ? 16 : 24}
						/>
						<span>{t("common.words.product-video")}</span>
					</SocialBtn>
				</div>

				{personal_note}
			</div>

			<div className="pt-6 sm:pt-12 pb-4 sm:pb-8 border-b-8 border-[#5F85EBCC] flex flex-col gap-3 sm:gap-8 items-center">
				<div className="flex flex-col sm:flex-row items-center gap-4 sm:gap-0 sm:items-start justify-center px-4">
					<img
						src="/images/logo.png"
						alt="polymerize"
						className="shrink-0 w-10 h-10"
					/>
					<h2 className="gradient-text sm:text-4xl max-w-2xl text-center">
						{t("cta-block.title")}
					</h2>
				</div>

				<PrimaryBtn
					className="text-sm md:text-lg sm:max-w-max sm:px-10"
					onClick={() => window.requestADemo()}
				>
					{t("common.cta.bookdemo")}
				</PrimaryBtn>

				<img
					className="w-full"
					src={FooterImage}
					alt="polymerize"
					loading="eager"
				/>
			</div>
		</section>
	)
}
